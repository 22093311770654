<template>
    <div>
        <div class="cont-search">
            <div class="search-l">
                <input placeholder="Search games" @focus="showRes=true" v-model="searchText" type="text">
            </div>
            <img v-if="!searchList.length" src="@/assets/phone2/sousuo.png" alt="" @click="searchGame">
            <img v-else src="@/assets/phone2/souso1.png" alt="" @click="searchGame">
        </div>
<!--        <div class="search-result" v-if="showRes">-->
<!--            <p class="no-search" v-if="!searchList.length&&searchText">Not found in search "{{searchText}}"</p>-->
<!--            <div class="result-top" v-if="searchList.length">-->
<!--                <p class="res-title">Search results</p>-->
<!--                <div class="cont-st-card" v-for="items in searchList" :key="items.id" @click="goDetails(items)">-->
<!--                    <img :src="items.logo" alt="">-->
<!--                    <p class="no-warp">{{items.title}}</p>-->
<!--                    <div></div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="result-top">-->
<!--                <p class="res-title">RECOMMEND FOR YOU :</p>-->
<!--                <div class="cont-st-card" v-for="items in recoList" :key="items.id+'1'" @click="goDetails(items)">-->
<!--                    <img :src="items.logo" alt="">-->
<!--                    <p class="no-warp">{{items.title}}</p>-->
<!--                    <div></div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="menu-Carousel">
            <div class="menu-Carousel-body">
                <div class="menu-card"
                v-for="(item,i) in classList"
                :key="i"
                @click="getMenuGame(item.key_name)">
                    {{item.key_name}}
                </div>
            </div>
        </div>
        <adContent></adContent>
        <div class="cont-st">
            <div class="cont-st-h">
                <p>
                    <!-- <img src="" alt=""> -->
                    {{firstList.title}} GAMES
                </p>
                <div @click="goselect(firstList.title)">more</div>
            </div>
            <div class="cont-st-card" v-for="items in firstList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
                <div></div>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p>
                    <!-- <img src="" alt=""> -->
                    {{gameList.title}} GAMES
                </p>
                <div @click="goselect(gameList.title)">more</div>
            </div>
            <div class="cont-list-card" v-for="items in gameList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
        <div class="game-blurb noselect">
            {{ blurbUrl }} is a global leader in online games, offering a diverse range of free-to-play titles across various genres including action, sports, strategy, and puzzle. With a rich history spanning over two decades, Miniclip has established itself as a trusted and popular destination for gamers worldwide. The platform boasts a user-friendly interface and seamless gameplay, ensuring that players can enjoy their favorite games without any hassle. Miniclip's commitment to quality and innovation has led to the development of numerous hit games, such as 8 Ball Pool, Agar.io, and Soccer Stars. The site also features a vibrant community where players can connect, compete, and share their experiences.
        </div>
    </div>
</template>

<script>
import { isMobile } from "@/utils/api"
import eventBus from '@/utils/eventBus'
import { 
    getClassList,
    getGame
} from '@/api/games'
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList:[],
                value:4,
                firstList:{},
                spanNum:0,
                searchText:'',
                classList: [],
                searchList:[],
                recoList: [],
                showRes:false,
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            this.getClassList();

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 7-2
            }

            let menukey = this.$route.params.menuKey;
            let is
            if(menukey){
                this.getMenuGame(menukey);
                is = 0
            }else{
                is = 1
            }
            this.getList(is);

            eventBus.$on('selectMenu',key=>{                    
                this.getMenuGame(key)
                eventBus.$emit('showmenu',false)
            });
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            async getClassList(){
                this.classList = await getClassList();                
            },
            async getList(is){
                
                let firstlist = await getGame({count:9})
                this.firstList = {
                    title: 'Hot',
                    games:firstlist
                }
                if(is){
                    let gameList = await getGame();
                    this.gameList = {
                        title: 'All',
                        games:gameList
                    }
                }
            },
            async getMenuGame(name){
                this.$router.push({
                    path:'/selectGame',
                    query:{
                        id:'more',
                        key:name
                    }
                })
            },
            goselect(item){
                this.$router.push({
                    path: '/selectGame',
                    query:{
                        id: 'more',
                        key: item
                    }
                })
            },
            async searchGame(){
                this.$router.push({
                    path: '/selectGame',
                    query:{
                        id: 'search',
                        key: this.searchText
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.cont-search{
    display: flex;
    margin: 10px auto 11px;
    width: 338px;
    height: 40px;
    background: #504BA9;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    line-height: 40px;
    .search-l{
        width: 281px;
        height: 40px; 
        border-radius: 10px;
        overflow: hidden;
        input{
            &::placeholder {
                color: #A0A9B6;
            }
            background: #504BA9;
            margin-left: 13px;
            width: 80%;
            height: 100%;
            border: none;
            outline: none;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #FFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
    img{
        margin-right: 6px;
        width: 34px;
        height: 34px;
    }
}
.search-result{
    padding: 0 20px;
    box-sizing: border-box;
    .no-search{
        height: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #0F1519;
        line-height: 28px;
        text-align: center;
        font-style: normal;
    }
    .result-top{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .res-title{
            width: 100%;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #0F1519;
            text-align: left;
            font-style: normal;
            .cont-st-card{
                p{
                    font-size: 14px;
                }
            }
        }
    }
}
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            height: 22px;
            img{
                width: 22px;
                height: 22px;
            }
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #0F1519;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
        div{
            margin-right: 12px;

            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #C3C3C3;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    span{
        width: 152px;
    }
}
.cont-st-card{
    margin-bottom: 13px;
    width: 152px;
    height: 153px;
    background: #D8D8D8;
    border-radius: 19px;
    text-align: center;
    position: relative;
    img{
        width: 152px;
        height: 152px;
        border-radius: 15px;
    }
    p{
        position: absolute;
        left: 11px;
        bottom: 6px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        z-index: 55;
    }
    div{
        width: 152px;
        height: 37px;
        background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, #000000 100%);
        border-radius: 0px 0px 18px 18px;
        position: absolute;
        bottom: 0;
    }
}
.cont-ed{
    .cont-ed-card{
        width: 162px;
        height: 86px;
        padding: 5px;
        margin: 8px 0 0 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        .ed-card-l{
            width: 76px;
            height: 76px;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ed-card-r{
            width: 70px;
            overflow: hidden;
            p{
                margin-bottom: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            .play{
                margin: 0 auto;
                width: 63px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                background: #504BA9;
                border-radius: 12px;

                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
}
.mid-list{
    .mid-list-rank{
        display: flex;
        justify-content: space-between;
        align-content: center;
        .rank-card{
            padding: 6px 8px 0;
            box-sizing: border-box;
            width: 106px;
            height: 152px;
            background: #F3EBDF;
            border-radius: 11px;
            text-align: center;
            img{
                width: 90px;
                height: 90px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                font-style: normal;
            }
        }
    }
    .mid-list-card{
        width: 335px;
        height: 80px;
        margin: 7px 0;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            width: 80px;
            height: 80px;
            background: #D8D8D8;
            border-radius: 13px;
            // border: 1px solid #FFFFFF;
        }
        .mid-c-mid{
            width: 162px;
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                text-align: left;
                font-style: normal;
            }
            div{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #747474;
                line-height: 17px;
                text-align: left;
                font-style: normal;
            }
        }
        .play{
            width: 74px;
            height: 25px;
            background: #D8C8B1;
            border-radius: 5px;
            line-height: 25px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 10px;
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
        }
    }
}
.cont-list{
    // &::after{
    //     content: '   ';
    //     flex: auto;
    //     display: block;
    //     width: 100px;
    // }
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #000;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.menu-Carousel{
    // margin-top: 20px;
    // width: calc(100vw - 30px);
    padding: 20px;
    box-sizing: border-box;
    // margin: 0 auto;
    overflow: hidden;
    .menu-Carousel-body{
        overflow: auto; 
        white-space: nowrap; 
        word-break: break-all; 
        // width: 100%;
        display: grid;
        grid-template-columns: repeat(21,1fr);
        grid-gap: 8px;
        .menu-card{
            // width: 69px;
            padding: 9px 18px;
            box-sizing: border-box;
            height: 37px;
            border-radius: 18px;
            border: 1px solid #D1D1D1;


            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #292C37;
            text-align: center;
            font-style: normal;
        }
    }
}
.overflow-list{
    .mobile-Carousel{
        margin-top: 20px;
        width: calc(100vw - 30px);
        // margin: 0 auto;
        overflow: hidden;
        .mobile-Carousel-body{
            overflow: auto; 
            white-space: nowrap; 
            word-break: break-all; 
            width: 100%;
            .Carousel-card{
                margin-right: 9px;
                display: inline-block;
                width: 106px;
                height: 152px;
                background: #F5D558;
                border-radius: 11px;
                border: 1px solid #000000;
                text-align: center;
                img{
                    display: block;
                    margin: 9px auto 6px;
                    width: 90px;
                    height: 90px;
                    background: #D8D8D8;
                    border-radius: 11px;
                }
                p{
                    width: 90px;
                    margin: 0 auto;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #000000;
                    font-style: normal;
                }
            }
        }
    }
}
.grid-list{
    .mobile-Carousel{
        margin-top: 20px;
        width: calc(100vw - 30px);
        // margin: 0 auto;
        overflow: hidden;
        .mobile-grid-body{
            overflow: auto; 
            white-space: nowrap; 
            word-break: break-all; 
            width: 100%;
            display: grid;
            grid-template-columns: repeat(10,1fr);
            grid-gap: 14px;
            .grid-card{
                img{
                    width: 121px;
                    height: 121px;
                    background: #D8D8D8;
                    border-radius: 18px;
                    border: 1px solid #000000;
                }
            }
        }
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.game-blurb{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>