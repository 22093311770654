<template>
    <div>
        <div class="header">
            <div class="header-l">
                <img src="@/assets/phone2/wondeful2.png" alt="" @click="goIndex">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            goIndex(){
                if(this.$route.path!='/') this.$router.push('/')
                else this.$router.go(0)
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.header{
    padding: 0 13px;
    box-sizing: border-box;
    z-index: 999;
    height: 50px;
    background: #504BA9;
    border-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    .header-l{
        img{
            width: 98px;
            height: 33px;
        }
    }
}
</style>